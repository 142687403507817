<template>
  <section id="ComunidadBanner" class="community my-5" data-aos="fade-down">
    <img
      class="d-none d-md-block desk-img"
      :src="require(`../../assets/${data.backgroundImg}`)"
      alt="Imagen de banner de seccion de comunidad"
    />
    <img
      class="d-block d-md-none background-img"
      src="../../assets/img/comunidad-bakcground-mobile.webp"
      alt="">
    <img
      class="d-block d-md-none inside-img"
      :src="require(`../../assets/${data.mobileImg}`)"
      alt="">
    <div class="__content">
      <div class="__description">
        <h2>
          <b>{{ data.title }}</b>
        </h2>
        <p>
          {{ data.description }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import store from "../../store";

export default {
  name: "ComunidadBanner",
  data() {
    return {
      data: {
        backgroundImg: "img/comunidad-background.webp",
        mobileImg: 'img/community-mobile-es.webp',
        title: "Comunidad",
        description:
          "Wetforest invita a todos los empresarios, emprendedores, inversionistas, gestores, profesionales y empresas especialistas que se identifiquen con nuestros valores y propósito.",
      },
      // title: "Comunidad",
      // description: `
      //  Wetforest invita a todos los empresarios, emprendedores, inversionistas, gestores, profesionales y empresas
      //  especialistas que se identifiquen con nuestros valores y propósito.
      // `,
      // img: "img/comunidad-img.png",
      // backgroundImg: "img/comunidad-background.png",
      // backgroundImgMobile: "img/comunidad-bakcground-mobile.png",
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },
  methods: {
    fetchData() {
      if (store.state.sections.communityBanner) {
        this.data = store.state.sections.communityBanner.components;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.community {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 64px 0;

  /* h2 {
    font-size: 2.813rem !important;
    font-family: "GentiumBold" !important;
  } */

  /* p {
    font-size: 0.813rem !important;
    font-family: "InterRegular" !important;
  } */
  .__content {
    height: 100%;
    width: 100%;
    max-width: 1166px;
    position: absolute;
    padding: 30px 20px;
    //backdrop-filter: saturate(100%) blur(10px);
  }

  .background-img {
    height: 1250px;
    width: 100%;
  }

  .__description {
    h3 {
      margin: 25px 0 0 0;
    }
  }

  .inside-img {
    position: absolute;
    bottom: 20%;
    width: 100%;
    max-width: 375px;
  }
  .desk-img {
    //position: absolute;
    //z-index: -1;
    height: auto;

    width: 100%;
    //max-height: 892px;
    max-width: 1920px;
  }
}

@media (min-width: 982px) {
  .community {
    .__content {
      padding: 65px;
      backdrop-filter: none;

      .__description {
        max-width: 50%;
      }

      // p {
      //   font-size: 2rem !important;
      // }
    }
  }
}
</style>
